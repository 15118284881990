import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

import { AuthServices } from "../auth.service";
import { Injectable } from "@angular/core";
import { endpoints } from "src/app/_config/url-endpoint";

// this.authService.getAccessHeaders() replacement
// const authHeaders = new HttpHeaders({
//   "Access-Control-Allow-Origin": "*",
//   authorization:
//     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ3MzMxNTgsImlkIjoiOTY0NDY2ZjAtNGMxYS00ZjVjLThjNTktZTkzY2ExMjZmODNkIiwidXNlcklkIjoiOTY0NDY2ZjAtNGMxYS00ZjVjLThjNTktZTkzY2ExMjZmODNkIiwibW9iaWxlTnVtYmVyIjoiNTIxNTQ2NDg4IiwiZW1haWwiOiJzaGVoYWJAeW9wbWFpbC5jb20iLCJjb3VudHJ5Q29kZSI6Iis5NzEiLCJyb2xlIjoiU1VQRVJfQURNSU4iLCJwYXJ0bmVySWQiOm51bGwsInNvdXJjZSI6IldFQl9QT1JUQUwiLCJpYXQiOjE3MzIxMDUxNTh9._bFCWbAOtHBbhO2hroF-lRjZB8F0W45Str-ZTKu5URQ",
//   // "Content-Type": "application/json",
//   "Cache-Control": "no-cache",
//   Pragma: "no-cache",
//   Expires: "-1",
//   "If-Modified-Since": "0",
//   // "ngrok-skip-browser-warning": "true",
// });

// replace this with endpoints.yachtYachtPage.[...]
// const ngrok_url = "http://localhost:4055/yacht/v1";

@Injectable({
  providedIn: "root",
})
export class YachtService {
  private saveYachtVal = new Subject<any>();

  constructor(private http: HttpClient, private authService: AuthServices) {}

  // done
  getYachtAnalytics(): Observable<any> {
    let url = `${endpoints.yacht.yachtUrl}/yacht-analytics`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getYachts(queryString): Observable<any> {
    let url = `${endpoints.yacht.yachtUrl}?${queryString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getMisslenaous(): Observable<any> {
    let url = `${endpoints.yacht.misslenaousUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getFacility(): Observable<any> {
    let url = `${endpoints.yacht.facilitiesUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getAmenities(): Observable<any> {
    let url = `${endpoints.yacht.amenetiesUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getWaterSports(): Observable<any> {
    let url = `${endpoints.yacht.waterSportsUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getCaterings(): Observable<any> {
    let url = `${endpoints.yacht.cateringUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getItineraries(): Observable<any> {
    let url = `${endpoints.yacht.itinerariesUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getYachtDetails(yachtId): Observable<any> {
    return this.http.get(
      endpoints.yacht.yachtUrl + `/${yachtId}`,
      this.authService.getAccessHeaders()
    );
  }

  // done
  createYacht(body): Observable<any> {
    return this.http.post(
      `${endpoints.yacht.yachtUrl}`,
      body,
      this.authService.getAccessHeaders()
    );
  }

  // done
  editYacht(yachtId, body): Observable<any> {
    return this.http.patch(
      endpoints.yacht.yachtUrl + `/${yachtId}`,
      body,
      this.authService.getAccessHeaders()
    );
  }

  getYachtCount(): Observable<any> {
    let tenantUrlString = "";
    // if (tenantId) {
    //   tenantUrlString = `?tenantId=${tenantId}`;
    // }
    let url = `${endpoints.yacht.yachtUrl}/status-count${tenantUrlString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  downloadFile(queryParams): Observable<any> {
    const url = `${endpoints.yacht.yachtUrl}/download?${queryParams}`;
    // const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(url, {
      headers: (this.authService.getAccessHeaders() as { headers: HttpHeaders })
        .headers,
      responseType: "blob",
    });
  }

  // done
  public setRefreshYacht(data: any) {
    this.saveYachtVal.next({ result: data });
  }

  // done
  public getRefreshYacht(): Observable<any> {
    return this.saveYachtVal.asObservable();
  }
}
