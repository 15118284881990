import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { endpoints } from "../_config/url-endpoint";

@Injectable({
  providedIn: "root",
})
export class AuthServices {
  public userData = new BehaviorSubject<any>({});
  private token: string;
  public readNotificationId = new Subject<any>();

  constructor(private http: HttpClient) {}

  // register(body): Observable<any> {
  //   return this.http.post(endpoints.auth.registerUrl, body);
  // }
  login(body): Observable<any> {
    return this.http.post(endpoints.auth.loginUrl, body);
  }
  verifyOtp(body): Observable<any> {
    return this.http.post(endpoints.auth.verifyUrl, body);
  }
  resend(body): Observable<any> {
    return this.http.post(endpoints.auth.resendUrl, body);
  }

  getMe(): Observable<any> {
    return this.http.get(endpoints.auth.meUrl);
  }
  putMe(body): Observable<any> {
    return this.http.put(endpoints.auth.meUrl, body);
  }

  getProfileImageData() {
    return this.userData.asObservable();
  }
  setProfileImageData(data) {
    this.userData.next(data);
  }

  getNotificationEvent() {
    return this.readNotificationId.asObservable();
  }
  sendNotificationEvent(id) {
    this.readNotificationId.next(id);
  }

  public getToken(): string {
    return (
      this.token ||
      (JSON.parse(localStorage.getItem("_u"))
        ? JSON.parse(localStorage.getItem("_u")).token
        : "")
    );
  }
  public getAccessHeaders(): object {
    const authorization = localStorage.getItem("access_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "x-access-token": authorization,
        authorization: "Bearer " + authorization,
        // "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "-1",
        "If-Modified-Since": "0",
      }),
    };
    return httpOptions;
  }

  public decodeJwtToken(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
}
