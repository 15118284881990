import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { AllBookingServices } from "src/app/_services/all-booking.service";
import { AnalyticsServices } from "../../../_services/analytics.service";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { CarServices } from "src/app/_services/car.service";
import { DriverServices } from "../../../_services/driver.service";
import { LoaderService } from "src/app/_services/loader.service";
import { PartnerService } from "../../../_services/partner.service";
import { Subscription } from "rxjs";
import { SuccessModalComponent } from "../success-modal/success-modal.component";
import { ErrorModalComponent } from "../error-modal/error-modal.component";
import { YachtPartnerService } from "src/app/_services/yacht/yacht-partner.service";
import { YachtService } from "src/app/_services/yacht/yacht-assets.service";

@Component({
  selector: "app-activate",
  templateUrl: "./activate.component.html",
  styleUrls: ["./activate.component.scss"],
})
export class ActivateComponent implements OnInit {
  public loading = false;
  public loaderSubscription: Subscription;
  public modalRef: BsModalRef;
  @Input() fromParent;

  constructor(
    private modal: NgbModal,
    private carServices: CarServices,
    private driverServices: DriverServices,
    private analyticsServices: AnalyticsServices,
    public activeModal: NgbActiveModal,
    private allBookingServices: AllBookingServices,
    private partnerService: PartnerService,
    public loaderService: LoaderService,
    private yachtPartnerService: YachtPartnerService,
    private yachtService: YachtService
  ) {}

  ngOnInit(): void {}
  hide() {
    this.modal.dismissAll();
  }

  confirm(type) {
    const requestBody = {
      id: this.fromParent.id,
      status: type,
    };
    const payloadDriver = {
      status: type,
    };
    const payloadPartner = {
      status: type,
    };
    const payloadDriverState = {
      driverState: type,
    };
    const customerChangeStatusPayload = {
      action: type,
    };
    let res: any;
    if (type === "BLOCKED") {
      res = {
        head: "Car Blocked.",
        content: `You have successfully blocked car with License # <span>${this.fromParent.licenseNumber}</span>. If the chauffeur assigned to this car has an active booking Then the status will be reflected once this booking is completed.`,
      };
    } else if (type === "ACTIVE") {
      res = {
        head: "Car Activated.",
        content: `Car with License Plate Number <span>${this.fromParent.licenseNumber}</span> has been activated. You can now assign a Chauffeur to this car.`,
      };
    } else if (type === "SUSPENDED") {
      res = {
        head: "Car Suspended.",
        content: `You have successfully suspended car with License # <span>${this.fromParent.licenseNumber}</span>. If the chauffeur assigned to this car has an active booking. then the status will be reflected once this booking is completed.`,
      };
    }

    if (this.fromParent.module == "car") {
      this.loading = true;
      this.carServices.changeCarStatus(requestBody).subscribe(
        (response) => {
          this.loading = false;
          this.successMessage(res);
          this.carServices.setRefreshCar(true);
        },
        (err) => {
          this.loading = false;
          // Handle error

          const error = err.error;
          if (error?.code === 400) {
            res = {
              head: "Error.",
              content: `${error.message}`,
            };
            this.errorMessage(res);
          }
        }
      );
    }

    if (this.fromParent.module == "driver") {
      const userInformation = this.fromParent.userInformation;
      this.loading = true;
      if (type === "OFFLINE") {
        this.driverServices
          .changeState(this.fromParent.id, payloadDriverState)
          .subscribe(
            (res) => {
              this.driverServices.setRefreshDriver(true);
              if (res) {
                this.loading = false;

                const res = {
                  head: "Chauffeur Marked Offline.",
                  content: `You have successfully marked the chauffeur Offline.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              }
            },
            (err) => {
              this.loading = false;
              // Handle error

              const error = err.error;
              if (error?.code === 400) {
                this.errorMessage(res);
              }
            }
          );
      } else {
        this.driverServices
          .changeStatus(this.fromParent.id, payloadDriver)
          .subscribe(
            (res) => {
              this.driverServices.setRefreshDriver(true);
              if (res) {
                this.loading = false;
                if (type === "ACTIVE") {
                  const res = {
                    head: "Chauffeur Activate.",
                    content: `Chauffeur account with Phone Number <span>${userInformation?.countryCode} ${userInformation?.mobileNumber}.</span> has been activated. You can now assign this Chauffeur to a car.`,
                  };
                  this.modal.dismissAll();
                  const modalRef = this.modal.open(SuccessModalComponent, {
                    scrollable: true,
                    windowClass: "success-modal",
                  });
                  modalRef.componentInstance.fromParent = res;
                  modalRef.result.then(
                    (result) => {},
                    (reason) => {}
                  );
                } else if (type === "BLOCKED") {
                  const res = {
                    head: "Chauffeur Blocked.",
                    content: `You have successfully blocked the chauffeur with Phone Number <span>${userInformation?.countryCode} ${userInformation?.mobileNumber}.</span>. If the chauffeur has an active booking, then the status will be reflected once this booking is complete.`,
                  };
                  this.modal.dismissAll();
                  const modalRef = this.modal.open(SuccessModalComponent, {
                    scrollable: true,
                    windowClass: "success-modal",
                  });
                  modalRef.componentInstance.fromParent = res;
                  modalRef.result.then(
                    (result) => {},
                    (reason) => {}
                  );
                } else if (type === "SUSPENDED") {
                  const res = {
                    head: "Chauffeur Suspended.",
                    content: `You have successfully suspended the chauffeur with Phone Number <span>${userInformation?.countryCode} ${userInformation?.mobileNumber}.</span>. If the chauffeur has an active booking, then the status will be reflected once this booking is complete.`,
                  };
                  this.modal.dismissAll();
                  const modalRef = this.modal.open(SuccessModalComponent, {
                    scrollable: true,
                    windowClass: "success-modal",
                  });
                  modalRef.componentInstance.fromParent = res;
                  modalRef.result.then(
                    (result) => {},
                    (reason) => {}
                  );
                }
              }
            },
            (err) => {
              this.loading = false;
              // Handle error

              const error = err.error;
              if (error?.code === 400) {
              }
            }
          );
      }
    } else if (this.fromParent.module == "customer") {
      const userInformation = this.fromParent.userInformation || {};
      this.analyticsServices
        .changeStatus(this.fromParent.id, customerChangeStatusPayload)
        .subscribe(
          (response) => {
            if (response && response.code === 200) {
              this.loading = true;
              this.onConfirm({ isSuccess: true });
              let successModalParam = {
                head: "",
                content: "",
              };
              if (type === "ACTIVE") {
                successModalParam.head = "Account Activate.";
                successModalParam.content = `You have successfully activated customer account with Phone Number <span>${userInformation?.countryCode} ${userInformation?.mobileNumber}.</span> We have notified the user and they will have access to the application.`;
              } else if (type === "BLOCKED") {
                successModalParam.head = "Account Blocked.";
                successModalParam.content = `You have successfully blocked customer account with Phone Number <span>${userInformation?.countryCode} ${userInformation?.mobileNumber}.</span> We have notified the user and they will no longer have access to the application.`;
              } else if (type === "SUSPENDED") {
                successModalParam.head = "Account Suspended.";
                successModalParam.content = `You have successfully suspended customer account with Phone Number  <span>${userInformation?.countryCode} ${userInformation?.mobileNumber}.</span> We have notified the user and they will no longer have access to the application.`;
              }
              this.modal.dismissAll();
              const modalRef = this.modal.open(SuccessModalComponent, {
                scrollable: true,
                windowClass: "success-modal",
              });
              modalRef.componentInstance.fromParent = successModalParam;
            } else {
              this.loading = false;
              this.modal.dismissAll();
            }
          },
          (err) => {
            this.loading = false;
            // Handle error

            const error = err.error;
            if (error?.code === 400) {
            }
          }
        );
    } else if (this.fromParent.module === "booking") {
      this.loading = true;
      if (this.fromParent.type === "endTrip") {
        this.allBookingServices.endTip(this.fromParent.payload).subscribe(
          (response) => {
            res = {
              head: "Trip Completed.",
              content: `Your trip completed.`,
            };
            this.loading = false;
            this.successMessage(res);
            this.allBookingServices.setRefreshBooking(true);
          },
          (err) => {
            this.loading = false;
            // Handle error

            const error = err.error;
            if (error?.code === 400) {
            }
          }
        );
      }
    } else if (this.fromParent.module == "partner") {
      this.loading = true;
      this.partnerService
        .changePartnerStatus(this.fromParent.id, payloadPartner)
        .subscribe(
          (res) => {
            this.partnerService.getPartnerDetails(this.fromParent.id);
            if (res) {
              this.loading = false;
              this.partnerService.setRefreshPartner(true);
              if (type === "ACTIVE") {
                const res = {
                  head: "Account Activated.",
                  content: `You have successfully activated the account for <span>${this.fromParent.email}</span>, We have notified this user and they will now have access to the portal.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              } else if (type === "BLOCKED") {
                const res = {
                  head: "Account Blocked.",
                  content: `You have successfully blocked the account for <span>${this.fromParent.email}</span>, We have notified this user and they will no longer have access to the portal.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              } else if (type === "SUSPENDED") {
                const res = {
                  head: "Account Suspended.",
                  content: `You have successfully suspended the account for <span>${this.fromParent.email}</span>, We have notified this user and they will no longer have access to the portal.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              }
            }
          },
          (err) => {
            this.loading = false;
            // Handle error

            const error = err.error;
            if (error?.code === 400) {
            }
          }
        );
    } else if (this.fromParent.module == "yachtPartner") {
      this.loading = true;
      this.yachtPartnerService
        .editPartner(this.fromParent.id, { status: type })
        .subscribe(
          (res) => {
            if (res) {
              this.loading = false;
              this.yachtPartnerService.setRefreshPartner(true);
              if (type === "ACTIVE") {
                const res = {
                  head: "Account Activated.",
                  content: `You have successfully activated the account for <span>${this.fromParent.email}</span>, We have notified this user and they will now have access to the portal.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              } else if (type === "BLOCKED") {
                const res = {
                  head: "Account Blocked.",
                  content: `You have successfully blocked the account for <span>${this.fromParent.email}</span>, We have notified this user and they will no longer have access to the portal.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              } else if (type === "SUSPENDED") {
                const res = {
                  head: "Account Suspended.",
                  content: `You have successfully suspended the account for <span>${this.fromParent.email}</span>, We have notified this user and they will no longer have access to the portal.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              }
            }
          },
          (err) => {
            this.loading = false;
            // Handle error

            const error = err.error;
            if (error?.code === 400) {
            }
          }
        );
    } else if (this.fromParent.module == "yacht") {
      this.loading = true;
      this.yachtService
        .editYacht(this.fromParent.yachtId, { status: type })
        .subscribe(
          (res) => {
            if (res) {
              this.loading = false;
              this.yachtService.setRefreshYacht(true);
              if (type === "ACTIVE") {
                const res = {
                  head: "Yacht Activated.",
                  content: `You have successfully activated yacht <span>${this.fromParent.name}</span>, We have notified this user and they will now have access to the portal.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              } else if (type === "BLOCKED") {
                const res = {
                  head: "Yacht Deactivated.",
                  content: `You have successfully deactivated yacht <span>${this.fromParent.name}</span>, We have notified this user and they will no longer have access to the portal.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              } else if (type === "DELETED") {
                const res = {
                  head: "Account Suspended.",
                  content: `You have successfully suspended the account for <span>${this.fromParent.email}</span>, We have notified this user and they will no longer have access to the portal.`,
                };
                this.modal.dismissAll();
                const modalRef = this.modal.open(SuccessModalComponent, {
                  scrollable: true,
                  windowClass: "success-modal",
                });
                modalRef.componentInstance.fromParent = res;
                modalRef.result.then(
                  (result) => {},
                  (reason) => {}
                );
              }
            }
          },
          (err) => {
            this.loading = false;
            // Handle error

            const error = err.error;
            if (error?.code === 400) {
            }
          }
        );
    }
  }

  successMessage(res) {
    this.modal.dismissAll();
    const modalRef = this.modal.open(SuccessModalComponent, {
      scrollable: true,
      windowClass: "success-modal",
    });
    modalRef.componentInstance.fromParent = res;
    modalRef.result.then(
      (result) => {
        isSuccess: true;
      },
      (reason) => {
        isSuccess: true;
      }
    );
  }

  errorMessage(res) {
    this.modal.dismissAll();
    const modalRef = this.modal.open(ErrorModalComponent, {
      scrollable: true,
      windowClass: "success-modal",
    });
    modalRef.componentInstance.fromParent = res;
    modalRef.result.then(
      (result) => {
        isSuccess: true;
      },
      (reason) => {
        isSuccess: true;
      }
    );
  }

  onConfirm(data) {
    this.activeModal.close(data); // Pass data back to the parent component
  }
}
