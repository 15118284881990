import { tripStatusList } from "./status-list";

export const config = {
  fbUrl: "https://www.facebook.com/",
  twitterURl: "https://twitter.com/",
  instagramUrl: "https://www.instagram.com/",
  linkedinUrl: "https://www.linkedin.com/",
  headerHeight: 23,
  footerHeight: 45,
  limit: 10,
  perPage: 10,
  pageNumber: 1,
  screenWidth: 991,
  max: 300,
};

export const configImage = {
  logo: "./../assets/images/logo-white.svg",
  logoIcon: "./../assets/images/logo-icon.svg",
  userDefault: "./../assets/images/user.png",
  dashboard: "./../assets/images/dashboard.svg",
  administration: "./../assets/images/administration.svg",
  cars: "./../assets/images/cars.svg",
  drivers: "./../assets/images/drivers.svg",
  customers: "./../assets/images/customers.svg",
  booking: "./../assets/images/booking.svg",
  tripCars: "./../assets/images/tripCars.svg",
  backIcon: "./../assets/images/icon-back.svg",
  keyIcon: "./../assets/images/icon-key.png",
  eyeIcon: "./../assets/images/icon-red-eye.png",
  eyeActiveIcon: "./../assets/images/icon-red-eye-active.png",
  signupImage: "./../assets/images/signup.png",
  searchIcon: "./../assets/images/icon-search.svg",
  viewIcon: "./../assets/images/icon-eye.svg",
  deleteIcon: "./../assets/images/icon-trash.svg",
  fileNameLength: 25,
  profilePicSize: 2050,
  editIcon: "./../assets/images/pencil-edit.svg",
  uploadIcon: "./../assets/images/upload.svg",
  closeIcon: "./../assets/images/close.svg",
  deletepopupIcon: "./../assets/images/delete.svg",
  locationIcon: "./../assets/images/location-icon.svg",
  locationEndIcon: "./../assets/images/location-icon.png",
  locationStartIcon: "./../assets/images/location-icon2.png",
  timeBigIcon: "./../assets/images/time-big-icon.png",
  locationBigIcon: "./../assets/images/location-big-icon.png",
  driversIcon: "./../assets/images/driver.svg",
  customerIcon: "./../assets/images/customertab.svg",
  location1Icon: "./../assets/images/001.svg",
  location2Icon: "./../assets/images/002.svg",
  location3Icon: "./../assets/images/003.svg",
  location4Icon: "./../assets/images/004.svg",
  cardIcon: "./../assets/images/card.svg",
  cashIcon: "./../assets/images/cash.svg",
  paypalIcon: "./../assets/images/paypal.svg",
  walletIcon: "./../assets/images/wallet.svg",
  infoCircle: "./../assets/images/info-circle.svg",
  nextArrow: "./../assets/images/next-arrow.svg",
  dollarIcon: "./../assets/images/dollar-icon.svg",
  dollarColors: "./../assets/images/dollar-icons.svg",
  userBlue: "./../assets/images/user-blue.svg",
  userVoilet: "./../assets/images/user-voilet.svg",
  cancelledTrips: "./../assets/images/cancelled-trips.svg",
  completedTrips: "./../assets/images/completed-trips.svg",
  OngoingTrips: "./../assets/images/on-going-trips.svg",
  scheduledTrips: "./../assets/images/scheduled-trips.svg",
  downArrow: "./../assets/images/down-arrow.svg",
  endLocation: "./../assets/images/end-location.svg",
  startLocation: "./../assets/images/start-location.svg",
  feedback: "./../assets/images/feedback.svg",
  carAddIcon: "./../assets/images/car-add.png",
  carRemoveIcon: "./../assets/images/car-remove.png",
  successIcon: "./../assets/images/success.png",
  userAddIcon: "./../assets/images/user-add.png",
  userRemoveIcon: "./../assets/images/user-remove.png",
  notificationsIcon: "./../assets/images/icon-notifications.svg",
  settingsIcon: "./../assets/images/icon-settings.svg",
  carIcon: "./../assets/images/car-icon.svg",
  approveIcon: "./../assets/images/approve.png",
  declineIcon: "./../assets/images/decline.png",
  downloadCSV: "./../assets/images/download.svg",
  menu: "./../assets/images/menu.svg",
  iconError: "./../assets/images/icon-error.svg",
  iconSuccess: "./../assets/images/icon-success.svg",
  check: "./../assets/images/check.svg",
  error: "./../assets/images/error.svg",
  reset: "./../assets/images/reload.svg",
  logoutImage: "./../assets/images/logout-menu-icon.svg",
  arrayLeft: "./../assets/images/array-left.svg",
  whiteCheckIcon: "./../assets/images/check-white.svg",
  checkCircle: "./../assets/images/check-circle-outline.svg",
  mapLocationStart: "./../assets/images/location-icon3.png",
  mapDriverLocation: "./../assets/images/driver-location.png",
  trash: "./../assets/images/trash.svg",
  carBlack: "./../assets/images/cars-black.svg",
  menuChat: "./../assets/images/menu.png",
  badge: "./../assets/images/badge.svg",
  customersIcon: "./../assets/images/customers-icon.svg",
  driverIcon: "./../assets/images/drivers-icon.svg",
  couponsIcon: "./../assets/images/coupons-icon.svg",
  paperPlane: "./../assets/images/paper-plane.svg",
  coupon: "./../assets/images/coupon.svg",
};

export const allBookingsImgs = {
  backButton: "./assets/images/icon-arrow-left.svg",
  origin: "./assets/images/origin.svg",
  destination: "./assets/images/destination.svg",
  minutes: "./assets/images/icon-minutes.svg",
  user: "./assets/images/user.png",
  iconPhone: "./assets/images/icon-phone.png",
  zoomIn: "./assets/images/zoom-in.svg",
  zoomOut: "./assets/images/zoom-out.svg",
};

export const metricConfigs = {
  AED: "AED",
  USD: "USD",
  KMS: "KMs",
  MIN: "Mins",
  HOURS: "Hours",
  PERCENT: "%",
};

export const RIDE_TYPE = {
  RIDE_NOW: "RIDE_NOW",
  SCHEDULED: "SCHEDULED",
  HOURLY: "HOURLY",
};

export const APP_TYPE = {
  RIDES: "RIDES",
  YACHTS: "YACHTS",
  PERSONAL_ASSISTANTS: "PERSONAL_ASSISTANTS",
};

export const TRIP_ACTIONS = {
  END_TRIP: "END_TRIP",
  CANCEL_FARE: "CANCEL_FARE",
  ASSIGN_CHAUFFEUR: "ASSIGN_CHAUFFEUR",
  REJECT_TRIP: "REJECT_TRIP",
  ENABLE_NOTIFY: "ENABLE_NOTIFY",
  CHANGE_TRIP_TIME: "CHANGE_TRIP_TIME",
  CHANGE_CAR_CATEGORY: "CHANGE_CAR_CATEGORY",
};

export const YACHT_ACTIONS = {
  FORWARD: "FORWARD",
  CONFIRM: "CONFIRM",
  REFUND: "REFUND",
  REJECT: "REJECT",
  NEW_REQUEST: "NEW_REQUEST",
};

export const countries = [
  { name: "United Arab Emirates", shortName: "United Arab Emirates" },
];

export const states = ["Dubai"];

export const paymentTermsList = [
  { value: "", label: "All" },
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
  { value: "Quarterly", label: "Quarterly" },
];

export const ROLE = {
  SUPER_ADMIN: "SUPER_ADMIN",
  PARTNER: "PARTNER",
};

export const bookingTimeSetup = {
  supportedHour: 5,
  supportedMinute: 30,
  AM: "AM",
  PM: "PM",
  supportedTimeHour: 12,
};

export const onTripStatusList = [
  tripStatusList.HEADING_TO_PICK_UP.name,
  tripStatusList.ON_TRIP.name,
  tripStatusList.AT_PICK_UP.name,
  tripStatusList.AT_STOP.name,
];

export const localStorageVal = {
  bookingTabs: "bookingTabs",
  tenantId: "tenantId",
  currency: "currency",
  timeZone: "timeZone",
  tenantCentreLocation: "tenantCentreLocation",
  showAllChauffeursForFleetPage: "showAllChauffeursForFleetPage",
  appTabs: "appTabs",
};

export const SUB_TYPE = {
  ONDEMAND: "ONDEMAND",
  SCHEDULED: "SCHEDULED",
  ON_DEMAND: "ON-DEMAND",
};

export const driverState = "ONLINE,ON_TRIP";

export const LIVE_FARE_TRIP_ALLOWED_STATUS = [
  tripStatusList.ON_TRIP.name,
  tripStatusList.AT_STOP.name,
];

// export const YACHT_TYPES = [
//   {display:"Luxury Yacht", value: "luxury"},
//   {display:"Event Yacht", value: "event"},
//   {display: "Party Boat", value:"party"},
//   {display: "Motor Yacht", value: "motor"},
//   {display: "Sailing Yacht", value:"sailing"},
// ];
export const YACHT_TYPES = [
  "All",
  "Luxury Yacht",
  "Event Yacht",
  "Party Boat",
  "Motor Yacht",
  "Sailing Yacht",
];

export const YACHT_PAYMENT_TERMS = [
  {
    id: "weekly",
    paymentTerm: "Weekly",
  },
  {
    id: "fortnightly",
    paymentTerm: "Fortnightly",
  },
  {
    id: "monthly",
    paymentTerm: "Monthly",
  },
];
export const YACHT_STATUS = {
  All: "",
  Active: "ACTIVE",
  Blocked: "BLOCKED",
  Pending: "PENDING_ACTIVATION",
  // DELETED: "deleted",
};
