import { environment } from "src/environments/environment";

export const endpoints = {
  allBookings: {
    getTripUrl: environment.analyticsURL + "/trip",
    tripCountUrl: environment.analyticsURL + "/trip/tripCount",
    tripDownloadUrl: environment.analyticsURL + "/trip/download",
    tripDetailUrl: environment.tripURL + "/trip/requests",
    estimationDetailUrl: environment.tripURL + "/trip/logs/estimate",
    tripMetricsUrl: environment.trackURL + "/metrics",
    tripTransactionLogsUrl: environment.paymentURL + "/logs",
    tripBreakdownLogsUrl: environment.tripURL + "/trip/logs",
    endTripUrl: environment.tripURL + "/trip/requests/end",
    allTripNotificationUrl: environment.notificationURL + "/view/notifications",
    callBackCheckNotificationUrl: environment.notificationURL + "/view/notifications/callback",
    scheduledTripCountUrl: environment.analyticsURL + "/trip/tripHourlyOrScheduledCount",
    assignChauffeurUrl: environment.tripURL + "/manage/assign",
    rejectUrl: environment.tripURL + "/manage/reject",
    enableNotifyUrl: environment.tripURL + "/trip/requests/enable-notify",
    validateChauffeurUrl: environment.tripURL + "/manage/assignment",
    tripChatUrl: environment.chatUrl + "/chat/getTripChat",
    tripRequestProcessUrl: environment.analyticsURL + "/trip/tripRequestProcessDetails",
    changeCarCategoryUrl: environment.tripURL + "/manage/change-scheduled-trip-car-category",
    changeTripTimeUrl: environment.tripURL + "/manage/change-scheduled-time",
    customerCollectorUrl: environment.collectorUrl + "/customer/eta",
    chauffeurDirectionLinkUrl: environment.collectorUrl + "/chauffeur/direction-click",
    createTripFineUrl: environment.tripURL + "/manage/fine",
    getTripFineUrl: environment.tripURL + "/manage/fine",
    refundTripFineUrl: environment.tripURL + "/manage/fine/refund",
    liveBillUrl: environment.tripURL + "/trip/logs/bill",
  },
  cars: {
    carDetailUrl: environment.carURL + "/car",
    createCarUrl: environment.carURL + "/car/create",
    updateCarUrl: environment.carURL + "/car/updateCar",
    getPartnerUrl: environment.analyticsURL + "/partner/activePartnerList",
    // analytics service API call
    carListUrl: environment.analyticsURL + "/car",
    carCountListUrl: environment.analyticsURL + "/car/carCount",
    downloadCarExcelUrl: environment.analyticsURL + "/car/download",
    changeCarStatusUrl: environment.carURL + "/car/change-status",
    getCarCategoryUrl: environment.carURL + "/carType",
    carAdministratorLogUrl: environment.analyticsURL + "/car/administration",
    chaufferListUrl: environment.analyticsURL + "/chauffer/chaufferList",
    assignedCarChauffeurUrl: environment.analyticsURL + "/car/getCarAssignChauffeur",
    assignedCarList: environment.carURL + "/assignment/list",
    selectCarForDriving: environment.carURL + "/assignment/select",
    carEarningsUrl: environment.analyticsURL + "/car/car-earnings",
  },
  // yachts: {
  //   yachtListUrl: environment.yachtURL + "/yacht",
  //   // carDetailUrl: environment.carURL + "/car",
  //   // createCarUrl: environment.carURL + "/car/create",
  //   // updateCarUrl: environment.carURL + "/car/updateCar",
  //   // getPartnerUrl: environment.analyticsURL + "/partner/activePartnerList",
  //   // // analytics service API call
  //   // carCountListUrl: environment.analyticsURL + "/car/carCount",
  //   // downloadCarExcelUrl: environment.analyticsURL + "/car/download",
  //   // changeCarStatusUrl: environment.carURL + "/car/change-status",
  //   // getCarCategoryUrl: environment.carURL + "/carType",
  //   // carAdministratorLogUrl: environment.analyticsURL + "/car/administration",
  //   // chaufferListUrl: environment.analyticsURL + "/chauffer/chaufferList",
  //   // assignedCarChauffeurUrl:
  //   //   environment.analyticsURL + "/car/getCarAssignChauffeur",
  // },
  report: {
    // report main page
    getReportsUrl: environment.reportURL + "/report",
    getReportsDownloadUrl: environment.reportURL + "/report/download",
    getReportsCountUrl: environment.reportURL + "/report/count",
    getReportDetailsByReportIdUrl: environment.reportURL + "/report/details",
    // report breakdown
    getReportBreakDownUrl: environment.reportURL + "/breakdown",
    downloadReportBreakDownUrl: environment.reportURL + "/breakdown/download",
    // actions
    settlementUrl: environment.reportURL + "/settlement",
    updateSettlementStatusUrl: environment.reportURL + "/settlement/status",
    // historic logs
    getReportTransactionLogsUrl: environment.reportURL + "/settlement/logs",
    downloadEarningReportUrl: environment.reportURL + "/chauffeur/earnings/download",
  },
  kpi: {
    getMonthlyTripsUrl: environment.analyticsURL + "/kpi/monthly-trip",
    getTripRatingUrl: environment.analyticsURL + "/kpi/tripWithRating",
    getFullfillmentRateUrl: environment.analyticsURL + "/kpi/fullfillmentRate",
    getPeakHourDriverCountUrl: environment.analyticsURL + "/kpi/peakHourDriverCount",
  },
  carTypes: {
    createCarTypeUrl: environment.carURL + "/carType",
    getCarTypeUrl: environment.carURL + "/carType",
    getCarTypesDownloadUrl: environment.carURL + "/carType/download",
    updateCarTypeUrl: environment.carURL + "/carType",
  },
  auth: {
    loginUrl: environment.userURL + "/auth",
    verifyUrl: environment.userURL + "/auth/verify",
    resendUrl: environment.userURL + "/auth/resend",
    meUrl: environment.userURL + "/me",
  },
  staticPage: {
    termsAndCondition: environment.staticURL + "/managePages/getManagePage/termsAndCondition",
    privacyPolicy: environment.staticURL + "/managePages/getManagePage/privacyPolicy",
    fineReasonUrl: environment.staticURL + "/fine-configuration",
    createFineReasonUrl: environment.staticURL + "/fine-configuration",
    updateFineReasonUrl: environment.staticURL + "/fine-configuration",
    fineListUrl: environment.staticURL + "/fine-configuration/list",
  },
  paymentPage: {
    retrievePaymentUrl: environment.paymentURL + "/stripePayment/retrievePaymentMethods",
    retrieveCustomerUrl: environment.paymentURL + "/stripePayment/retrieveCustomer",
    cancelFare: environment.tripURL + "/manage/default",
    addRefund: environment.tripURL + "/manage/refund",
    editRefund: environment.tripURL + "/manage/refund/",
    getRefundedTransactions: environment.tripURL + "/manage/refund/list",
    getCancelFareTransactions: environment.tripURL + "/manage/default",
  },
  partnerPage: {
    createPartner: environment.userURL + "/partner",
    editPartner: environment.userURL + "/partner/",
    getPartnerDetails: environment.userURL + "/partner/",
    getPartners: environment.analyticsURL + "/partner/",
    getPartner: environment.userURL + "/partner",
    changePartnerStatus: environment.userURL + "/partner/change-status/",
    partnerUrl: environment.userURL,
    getPartnerCountUrl: environment.analyticsURL + "/partner/partnerCount",
    getPartnerDownloadUrl: environment.analyticsURL + "/partner/download",
  },
  // yachtPartnerPage: {
  //   getPartners: environment.yachtURL + "/partner",
  //   getPartnerDetails: environment.yachtURL + "/partner/",
  //   createPartner: environment.yachtURL + "/partner",
  //   editPartner: environment.yachtURL + "/partner/",
  //   getPartnerCount: environment.yachtURL + "/partner/status-count",
  //   // getPartner: environment.yachtURL + "/partner",
  //   // changePartnerStatus: environment.yachtURL + "/partner/change-status/",
  //   // partnerUrl: environment.yachtURL,
  //   // getPartnerDownloadUrl: environment.yachtURL + "/partner/download",
  // },
  customerPage: {
    changeStatus: environment.userURL + "/user/action/",
    deviceDetails: environment.userURL + "/user/device-details",
    otpDetails: environment.userURL + "/user/last-generated-otp",
    getCustomerUrl: environment.analyticsURL + "/customer/customerQuotation",
    getCustomerTotalSpendUrl: environment.analyticsURL + "/customer/totalSpend",
    getCarTypePerformanceUrl: environment.analyticsURL + "/customer/carTypeWiseTrip",
  },
  driverPage: {
    getChaufferUrl: environment.analyticsURL + "/chauffer",
    getChaufferCountUrl: environment.analyticsURL + "/chauffer/chaufferCount",
    getChaufferDownloadUrl: environment.analyticsURL + "/chauffer/download",
    driverUrl: environment.userURL + "/driver",
    assignCarUrl: environment.userURL + "/driver/assign-car",
    changeStatusUrl: environment.userURL + "/driver/change-status",
    performanceUrl: environment.analyticsURL + "/chauffer/performance",
    workingUrl: environment.analyticsURL + "/chauffer/working",
    activityUrl: environment.analyticsURL + "/chauffer/activity",
    earningsUrl: environment.analyticsURL + "/chauffer/driver-earnings",
    acceptanceRateUrl: environment.analyticsURL + "/chauffer/acceptance-rate",
    activeChauffer: environment.analyticsURL + "/chauffer/activeChauffer",
    changeStateByAdmin: environment.userURL + "/driver/update-state-by-admin",
    fleetCount: environment.analyticsURL + "/chauffer/fleet-count",
    otpDetails: environment.userURL + "/user/last-generated-otp",
  },
  analyticsPage: {
    getCustomerUrl: environment.analyticsURL + "/customer",
    getCustomerCountUrl: environment.analyticsURL + "/customer/customerCount",
    getCustomerDownloadUrl: environment.analyticsURL + "/customer/download",
    getAdministrativeLogsUrl: environment.userURL + "/user/get-administrative-logs",
    getPaymentCustomerUrl: environment.paymentURL + "/digital/paymentMethod",
  },
  dashboardPage: {
    getDashboardKeyInsights: environment.analyticsURL + "/dashboard/dashboardKeyInsights",
    getDashboardRevenue: environment.analyticsURL + "/dashboard/revenue",
    getDashboardTotalRevenue: environment.analyticsURL + "/dashboard/total-revenue",
    getDashboardBookings: environment.analyticsURL + "/dashboard/bookings",
    getDashboardCustomerRegistrations: environment.analyticsURL + "/dashboard/customers",
    getDashboardKeyPerformance: environment.analyticsURL + "/dashboard/tripsPerformance",
    getDashboardTopCustomers: environment.analyticsURL + "/dashboard/topCustomers",
    getDashboardTopChauffeurs: environment.analyticsURL + "/dashboard/topDrivers",
    getTripFineUrl: environment.analyticsURL + "/fine/count",
  },
  cancellationReasonPage: {
    createCancellationReason: environment.staticURL + "/static/reason",
    getCancellationReason: environment.staticURL + "/static/reason",
    downloadCancellationReason: environment.staticURL + "/static/reason/download",
    updateCancellationReason: environment.staticURL + "/static/reason",
    deleteCancellationReason: environment.staticURL + "/static/reason",
  },
  sockets: {
    tripSocket: environment.socketURL,
  },
  feed: {
    getFeedUrl: environment.notificationURL + "/feed",
  },
  carModels: {
    createCarModels: environment.carURL + "/carModel",
    getCarModels: environment.carURL + "/carModel",
    updateCarModels: environment.carURL + "/carModel",
  },
  fleet: {
    hotZones: environment.analyticsURL + "/analytics/hot-zones",
    categoryCount: environment.analyticsURL + "/analytics/category-count",
  },
  copilot: {
    getSettingAvailability: environment.copilotUrl + "/availability",
    setSettingAvailability: environment.copilotUrl + "/availability/model",
    seCarAvailability: environment.copilotUrl + "/availability/car",
    getTripPreferenceUrl: environment.copilotUrl + "/preference",
  },
  tenant: {
    getTenantUrl: environment.analyticsURL + "/tenant",
  },
  yacht: {
    sightUrl: environment.yachtUrl + "/sight",
    partnerUrl: environment.yachtUrl + "/partner",
    cateringUrl: environment.yachtUrl + "/addons/catering",
    waterSportsUrl: environment.yachtUrl + "/addons/water-sports",
    misslenaousUrl: environment.yachtUrl + "/addons/misslenaous",
    itinerariesUrl: environment.yachtUrl + "/itineraries",
    yachtUrl: environment.yachtUrl + "/yacht",
    facilitiesUrl: environment.yachtUrl + "/addons/facilities",
    amenetiesUrl: environment.yachtUrl + "/extras/ameneties",
    yachtBookingUrl: environment.yachtBookingUrl + "/booking",
    yachtAdminBookingUrl: environment.yachtBookingUrl + "/admin/booking",
    yachtOperatorNotesUrl: environment.yachtBookingUrl + "/operator-notes",
    yachtAddonsUrl: environment.yachtBookingUrl + "/addons",
    lockedRateUrl: environment.yachtBookingUrl + "/locked-rate",
  },
  personalAssistant: {
    servicesUrl: environment.personalAssistantURL + "/admin/service-list",
    packagesUrl: environment.personalAssistantURL + "/admin/packages",
    customPackagesUrl: environment.personalAssistantURL + "/admin/custom-packages",
    subscriptionsUrl: environment.personalAssistantURL + "/admin/subscriptions",
    tasksUrl: environment.personalAssistantURL + "/admin/tasks",
    tasksDownloadUrl: environment.personalAssistantURL + "/admin/tasks/download",
    subTasksUrl: environment.personalAssistantURL + "/admin/sub-tasks",
    taskAnalyticsUrl: environment.personalAssistantURL + "/admin/analytics/tasks",
  },
  alert: {
    getActivityAlertListUrl: environment.alertUrl + "/activity/alert/list",
    getStopMonitoringListUrl: environment.alertUrl + "/stop/monitoring/list",
    getTripRouteUrl: environment.alertUrl + "/trip/route",
  },
};
